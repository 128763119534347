import React from "react";
import "./App.css";
import { SESClient, SendEmailCommand } from "@aws-sdk/client-ses";

function App() {
  const [language, setLanguage] = React.useState("English");
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [addressLineOne, setAddressLineOne] = React.useState("");
  const [addressLineTwo, setAddressLineTwo] = React.useState("");
  const [city, setCity] = React.useState("");
  const [state, setState] = React.useState("");
  const [zipCode, setZipCode] = React.useState("");
  const [isSubmitted, setSubmitted] = React.useState(false);
  const [failedSubmit, setFailedSubmit] = React.useState(false);
  const [missingField, setMissingField] = React.useState(false);

  async function handleEmailSend() {
    if (
      name.length === 0 ||
      email.length === 0 ||
      addressLineOne.length === 0 ||
      city.length === 0 ||
      state.length === 0 ||
      zipCode.length === 0
    ) {
      setMissingField(true);
    }

    setMissingField(false);

    try {
      const client = new SESClient({
        region: "us-east-2",
        credentials: {
          accessKeyId: "AKIAZI2LIDZ2TQOWAS4F",
          secretAccessKey: "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",
        },
      });
      await client.send(
        new SendEmailCommand({
          Destination: { ToAddresses: ["byfaiththrujesus@gmail.com"] },
          Source: "byfaiththrujesus@gmail.com",
          Message: {
            Body: {
              Html: {
                Charset: "UTF-8",
                Data: `<h1>You got a new Bible request!</h1><b>Full name:</b> ${name} <br/><b>Email address:</b> ${email} <br/><b>Language:</b> ${language} <br/> <b>Address line one:</b> ${addressLineOne}<br/><b>Address line two:</b> ${addressLineTwo}<br/><b>City:</b> ${city} <br/><b>State:</b> ${state}<br/><b>ZIP code:</b> ${zipCode}`,
              },
              Text: {
                Charset: "UTF-8",
                Data: "You got a new Bible request!",
              },
            },
            Subject: {
              Charset: "UTF-8",
              Data: "You got a new Bible request!",
            },
          },
        })
      );

      setSubmitted(true);
    } catch {
      setFailedSubmit(true);
    }
  }

  return (
    <div className="App">
      <div className="video-and-title">
        <h1>The Bible Way To Heaven (English)</h1>
        <div className="video-container">
          <video controls>
            <source src="https://by-faith-thru-jesus.s3.us-east-2.amazonaws.com/Bible+Way+to+Heaven+++Pastor+Roger+Jimenez+++VBC.mp4" />
          </video>
        </div>
      </div>
      <div className="video-and-title">
        <h1>El Camino Bíblico al Cielo (Spanish)</h1>
        <div className="video-container">
          <video controls>
            <source src="https://by-faith-thru-jesus.s3.us-east-2.amazonaws.com/El+Camino+Bi%CC%81blico+al+Cielo+-+%40Verity+Baptist+Church.mp4" />
          </video>
        </div>
      </div>
      <div id="bible-form">
        <h1>Request a FREE Bible</h1>
        <i style={{ textAlign: "center" }}>
          Available in the continental US only
        </i>
        {isSubmitted ? (
          <h2 id="confirm-submit">
            Your request has been sent! You will receive a confirmation email
            once the Bible has been shipped.
          </h2>
        ) : (
          <>
            <div className="form-field">
              <label>Language</label>
              <select
                name="bible-language"
                onChange={(event) => setLanguage(event.target.value)}
              >
                <option value="English">English - KJV</option>
                <option value="Spanish">Spanish - RVG</option>
                <option value="Portuguese">Portuguese - ACF</option>
              </select>
            </div>
            <div className="form-field">
              <label>Full Name</label>
              <input
                type="text"
                onChange={(event) => setName(event.target.value)}
              />
            </div>
            <div className="form-field">
              <label>Email Address</label>
              <input
                type="text"
                onChange={(event) => setEmail(event.target.value)}
              />
            </div>

            <div className="form-field">
              <label>Address Line 1</label>
              <input
                type="text"
                onChange={(event) => setAddressLineOne(event.target.value)}
              />
            </div>

            <div className="form-field">
              <label>Address Line 2</label>
              <input
                type="text"
                onChange={(event) => setAddressLineTwo(event.target.value)}
              />
            </div>

            <div className="form-field">
              <label>City</label>
              <input
                type="text"
                onChange={(event) => setCity(event.target.value)}
              />
            </div>

            <div className="form-field">
              <label>State</label>
              <input
                type="text"
                onChange={(event) => setState(event.target.value)}
              />
            </div>

            <div className="form-field">
              <label>ZIP Code</label>
              <input
                type="text"
                onChange={(event) => setZipCode(event.target.value)}
              />
            </div>

            <button onClick={handleEmailSend}>Submit</button>
            {failedSubmit && (
              <b style={{ color: "red" }}>
                Something went wrong! Try again later.
              </b>
            )}
            {missingField && (
              <b style={{ color: "red" }}>
                Please fill in all of the required fields.
              </b>
            )}
          </>
        )}
      </div>

      <div className="video-and-title">
        <h1>New World Order Bible Versions</h1>
        <div className="video-container">
          <video controls>
            <source src="https://by-faith-thru-jesus.s3.us-east-2.amazonaws.com/New+World+Order+Bible+Versions+(Full+Movie).mp4" />
          </video>
        </div>
      </div>
    </div>
  );
}

export default App;
